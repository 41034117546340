<template lang="pug">
  div#templateColsContainer.px-1.pt-1
    .justify-content-center.h-100(v-if='isLoadingProductProvider || isCreatingProductProvider || isUpdatingProductProvider')
      .content-loader-center.m-0.h-100
        .text-center.flex-center
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            | Chargement du formulaire...
    b-row.content-scrollable-sticky.m-0.justify-content-center(v-else :key="key" style="height: calc(100% - 120px)")
      div.content-scrollable-sticky-main.cell-right.h-100(cols='12' md="9")
        .px-1.h-100
          validation-observer(ref='formProductProvider' #default='{ errors }')
            b-form.h-100(autocomplete='off')
              b-row.my-0.pb-2
                b-col.content-header.d-flex.align-items-center.justify-content-between
                  .d-flex.align-items-center
                    h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
                      | {{ $route.meta.pageTitle }}
                    .content-header-search.mr-1
                      h3.m-0 {{ productProvider.label }}
                  .content-header-actions.d-flex
                    feather-icon.cursor-pointer(icon='XIcon' size='24' @click='cancel()')
              #providers.pt-0.h-100(style="overflow-x: hidden")
                div
                  .d-flex 
                    b-row.my-0.flex
                      b-col.py-0.mb-1.w-100(cols="12")
                        .btn-group.w-100(role='group' aria-label='Basic example')
                          button.btn.justify-content-center(:class="productProvider.productType == 0 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="productProvider.productType = 0, productProvider.unitId = 1") Fourniture
                          button.btn.justify-content-center(:class="productProvider.productType == 5 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="productProvider.productType = 5, productProvider.unitId = 2") Main d'oeuvre
                          button.btn.justify-content-center(:class="productProvider.productType == 2 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="productProvider.productType = 2, productProvider.unitId = 1") Matériel
                          button.btn.justify-content-center(:class="productProvider.productType == 6 ? 'btn-primary' : 'btn-outline-primary'" type='button' @click="productProvider.productType = 6, productProvider.unitId = 1") Autres
                  b-row.my-0
                    b-col.py-0.mb-1(cols="12")
                      b-form-group(label="Fournisseur *")
                        validation-provider(#default='{ errors }' name='selectCompany' rules="required")
                          v-select.w-100#company(ref="selectCompany" :reduce="(el) => el.value" v-model="productProvider.companyId" :loading='isLoadingCompaniesList' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='companyOptions' placeholder='Rechercher un fournisseur')
                            template(v-slot:no-options='')
                              template  Aucun r&eacute;sultat trouv&eacute;
                            li.border-bottom.p-1.py-50(slot='list-header')
                              b-button.w-100.d-flex.justify-content-center.align-items-center(@click="newProvider" variant='primary' size='sm')
                                | + Nouveau fournisseur
                                span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                                  | open_in_new
                          small.text-danger {{ errors[0] }}
                  b-row.my-0
                    b-col.py-0.mb-1(cols="3")
                      b-form-group(label="Référence d'achat *")
                        validation-provider(#default='{ errors }' name='reference' rules="required")
                          b-form-input(v-model="productProvider.reference" id="reference" :state="errors.length > 0 ? false : null")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols="9")
                      b-form-group(label="Libellé fournisseur")
                        validation-provider(#default='{ errors }' name='label')
                          b-form-input(v-model="productProvider.label" id="label" :state="errors.length > 0 ? false : null")
                  b-row.my-0
                    b-col.py-0.mb-1(cols="12")
                      b-form-group(label="Description *")
                        validation-provider(#default='{ errors }' name='description' rules="required")
                          quill-editor( id="text-area-designation" v-model="productProvider.description" :options="editorOption" :state="errors.length > 0 ? false : null")
                          small.text-danger {{ errors[0] }}
                  b-row.my-0
                    b-col.py-0.mb-1(cols="4")
                      b-form-group(label="Quantité par défaut *")
                        validation-provider(#default='{ errors }' name='quantity' rules="required")
                          b-form-input(v-model="productProvider.quantity" :step="0.1" :min="0" id="quantity" type="number")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols="4")
                      b-form-group(label="Unité *")
                        validation-provider(#default='{ errors }' name='unit' rules="required")
                          v-select(v-model="productProvider.unitId" :reduce="(el) => el.value" :options="unitOptions" :state="errors.length > 0 ? false : null")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols="4")
                      b-form-group(label="Catégorie d'achat")
                        validation-provider(#default='{ errors }' name='purchaseCategoryId')
                          Treeselect.text-left.w-100(v-model="productProvider.purchaseCategoryId" :multiple="false" :options="purchaseCategoriesTreeList" value-consists-of="LEAF_PRIORITY" noOptionsText="Aucun résultat trouvé" noChildrenText="Aucun résultat trouvé" :show-count="true" placeholder="" search-nested)
                  div.d-flex.justify-content-between
                    div.d-flex.align-items-center
                      h3.text-primary {{ productProvider.useDegressivePurchaseRate ? `Tarif dégressif d'achat / remise` : `Tarif achat / remise` }}
                      b-form-checkbox.ml-1#useDegressivePurchaseRate(v-model='productProvider.useDegressivePurchaseRate' @change="actionDegressivePurchaseRateArray")
                      label.mr-0(for="useDegressivePurchaseRate")
                        em Appliquer un tarif dégressif
                    div.py-0.pr-0.mb-1.d-flex.justify-content-end.align-items-center
                      b-form-group
                        validation-provider(#default='{ errors }' name='ecoContribution')
                          b-input-group(prepend='Eco-contribution')
                            b-form-input(v-model="productProvider.ecoContribution" type="number" id="ecoContribution" :state="errors.length > 0 ? false : null")
                  b-row.my-0(v-if="!productProvider.useDegressivePurchaseRate")
                    b-col.py-0.mb-1(cols="6")
                      b-form-group(label="Prix d'achat HT *")
                        validation-provider(#default='{ errors }' name='netPrice' rules="required")
                          b-form-input(v-model="productProvider.netPrice" :step="0.01" :min="0" id="netPrice" type="number")
                          small.text-danger {{ errors[0] }}
                    b-col.py-0.mb-1(cols="6")
                      b-form-group(label="Remise par défaut (%)")
                        validation-provider(#default='{ errors }' name='discount')
                          b-form-input(v-model="productProvider.discount" :step="0.01" :min="0" id="discount" type="number")
                  .d-flex.align-items-end(v-else v-for="(elementDegressivePurchaseRate, indexDegressivePurchaseRate) of productProvider.degressivePurchaseRates" :key="indexDegressivePurchaseRate")
                    div(style="flex:1")
                      b-row.my-0 
                        b-col.py-0.mb-1.d-flex.flex-column
                          label.w-100.text-center(label-for='Quantity' v-if="indexDegressivePurchaseRate == 0") Quantité *
                          .d-flex.align-items-center(style="gap:5px; padding-left:12px;" v-if="indexDegressivePurchaseRate != productProvider.degressivePurchaseRates.length - 1")
                            span Entre
                            b-form-input#quantityMin.not-autocomplete.d-flex(disabled type="number" v-model='elementDegressivePurchaseRate.quantityMin' autocomplete='nope' aria-autocomplete='nope' style="flex:1")
                            span et
                            b-form-input#quantityMax.not-autocomplete.d-flex(type="number" v-model='elementDegressivePurchaseRate.quantityMax' autocomplete='nope' aria-autocomplete='nope' style="flex:1" @blur="changeQuantityMaxProvider(indexDegressivePurchaseRate)")
                          b-row.align-items-center(v-else)
                            span.font-weight-bold.p-1.w-100.text-center {{ elementDegressivePurchaseRate.quantityMin }} ou plus
                        b-col.py-0.mb-1.d-flex.flex-column
                          label.w-100.text-right(label-for='price' v-if="indexDegressivePurchaseRate == 0") Prix unitaire HT *
                          b-form-group.w-100
                            validation-provider(#default='{ errors }' name='price' rules="required")
                              b-form-input#price.w-100.not-autocomplete(type="number" v-model='elementDegressivePurchaseRate.price' autocomplete='nope' aria-autocomplete='nope')
                              small.text-danger {{ errors[0] }}
                        b-col.py-0.mb-1.d-flex.flex-column
                          label.w-100.text-right(label-for='discount' v-if="indexDegressivePurchaseRate == 0") Remise par défaut (%)
                          b-form-group.w-100
                            b-form-input#discount.w-100.not-autocomplete(type="number" v-model='elementDegressivePurchaseRate.discount' autocomplete='nope' aria-autocomplete='nope')
                        //- b-col.py-0.mb-1.d-flex.flex-column
                        //-   label.w-100.text-right(label-for='salePrice' v-if="indexDegressivePurchaseRate == 0") Prix de vente u HT
                        //-   b-form-group.w-100
                        //-     b-form-input#salePrice.w-100.not-autocomplete(type="text" v-model='elementDegressivePurchaseRate.salePrice' autocomplete='nope' aria-autocomplete='nope')
                        //- b-col.py-0.mb-1
                        //-   label.w-100.text-right(v-if="indexDegressivePurchaseRate == 0") Marge brute
                        //-   b-form-group.w-100
                        //-     .false-input.disabled
                        //-       .false-input-content
                        //-         span.text-primary.false-input-sub-content.fi-border-r {{ formatPercent(elementDegressivePurchaseRate.marginRate) }}
                        //-         span.text-primary.false-input-sub-content {{ formatCurrency(elementDegressivePurchaseRate.grossMarginHT) }}
                    div.py-0.mb-1.ml-1(v-if="productProvider.degressivePurchaseRates.length > 2")
                      b-button(variant="outline-danger" @click="deleteDegressivePurchaseRateLocal(indexDegressivePurchaseRate, elementDegressivePurchaseRate)") 
                        feather-icon.m-0(icon="TrashIcon")
                  div.mb-1(v-if="productProvider.useDegressivePurchaseRate" style="padding-left:12px;")
                    small(@click="addDegressiveSaleRateProvider").cursor-pointer.text-primary + Ajouter une ligne
                  b-row.my-0
                    b-col.py-0.mb-1
                      h3.text-primary {{ `Notes supplémentaires` }}
                      b-form-group
                        validation-provider(#default='{ errors }' name='internalNote')
                          quill-editor( id="text-area-designation" v-model="productProvider.internalNote" :options="editorOption" :state="errors.length > 0 ? false : null")
                          small.text-danger {{ errors[0] }}
              b-row.my-0.justify-content-center
                b-col.py-0(cols='12')
                  .d-flex.justify-content-between(style='border-top: 1px solid #e2e2e2; padding-top: 0.5rem;')
                    div
                      b-button(variant='outline-primary' @click='cancel()')
                        feather-icon(icon='ArrowLeftIcon')
                          | Annuler
                    b-button(variant='primary' @click='addProductProvider')
                      feather-icon(icon='SaveIcon' v-if='productProvider.id')
                      feather-icon(icon='PlusIcon' v-else)
                      | {{ productProvider.id ? &apos;Modifier&apos; : &apos;Ajouter&apos; }}
              
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";
import {
  BFormRadioGroup,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BTable,
} from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";
import {
  formatNumber,
  formatCurrency,
  formatPercent,
} from "@/types/api-orisis/library/FormatOperations.ts";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  props: {
    id: {
      default: null,
    },
    companyId: {
      default: null,
    },
  },
  data() {
    return {
      key: 0,
      dataOrigine: {},
      ProductProviderDefaultProperties,
      productProvider: {},
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      categoryOptions: [],
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingProductProvider",
      "productCategoryTree",
      "unitsList",
      "workspaceSelected",
      "isLoadingCompaniesList",
      "purchaseCategoriesTreeList",
      "isCreatingProductProvider",
      "isUpdatingProductProvider",
    ]),
    companyOptions() {
      return this.$store.getters.companiesList
        .filter((company) => company.companyType.id == 4)
        .map((elem) => {
          return {
            label: elem.name,
            value: elem.id,
          };
        });
    },
    unitOptions() {
      return this.$store.getters.unitsList.map((elem) => {
        return {
          label: elem.label,
          value: elem.id,
        };
      });
    },
  },
  async created() {
    this.productEvent();
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({});
    }
  },
  mounted() {
    this.getProductCategoriesTree({});
    this.getSaleCategoriesTree({});
    this.getPurchaseCategoriesTree({});
  },
  methods: {
    ...mapActions([
      "getProductCategoriesTree",
      "getSaleCategoriesTree",
      "getPurchaseCategoriesTree",
      "getUnits",
      "getProductProviderById",
      "updateProductProvider",
      "createProductProvider",
      "getCompanies",
      "deleteDegressivePurchaseRate",
    ]),
    formatNumber,
    formatCurrency,
    formatPercent,
    async productEvent() {
      if (!this.unitsList || this.unitsList.length == 0) {
        this.getUnits({});
      }
      this.getCompanies({ companyTypeId: 4 });
      if (this.id != "" && this.id !== null && this.id != 0) {
        this.getProductProviderById({
          productProviderId: this.id,
        }).then((res) => {
          this.productProvider = res;
          this.dataOrigine = structuredClone(this.productProvider);
        });
      } else {
        this.productProvider = this.ProductProviderDefaultProperties({
          companyId: this.companyId ? this.companyId : null,
        });
      }
    },
    addProductProvider(e) {
      e.preventDefault();
      this.$refs.formProductProvider
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.productProvider.id) {
              this.updateProductProvider({
                productProvider: this.productProvider,
                reload: true,
              }).then(() => {
                this.$nextTick(() => {
                  this.$tabs.close({ to: "/product-provider" });
                });
              });
            } else {
              this.createProductProvider({
                productProvider: this.productProvider,
                reload: true,
              }).then(() => {
                this.$nextTick(() => {
                  this.$tabs.close({ to: "/product-provider" });
                });
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.productProvider)) {
        if (
          this.$route.params.routeOrigine &&
          this.$route.params.routeOrigine !== ""
        ) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close();
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (
                this.$route.params.routeOrigine &&
                this.$route.params.routeOrigine !== ""
              ) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
    actionDegressivePurchaseRateArray(value) {
      if (value) {
        this.productProvider.degressivePurchaseRates = [
          {
            quantityMin: 0,
            quantityMax: 2,
            price: this.productProvider.netPrice,
            discount: 0,
            ecoContribution: 0,
          },
          {
            quantityMin: 2.01,
            quantityMax: null,
            price: this.productProvider.netPrice,
            discount: 0,
            ecoContribution: 0,
          },
        ];
        this.productProvider.netPrice = 0;
      } else {
        this.productProvider.netPrice =
          this.productProvider.degressivePurchaseRates[0].price;
        this.productProvider.degressivePurchaseRates = [];
      }
      this.key++;
    },
    addDegressiveSaleRateProvider() {
      let number =
        this.productProvider.degressivePurchaseRates[
          this.productProvider.degressivePurchaseRates.length - 1
        ].quantityMin + 2.99;
      this.productProvider.degressivePurchaseRates[
        this.productProvider.degressivePurchaseRates.length - 1
      ].quantityMax = number;
      this.productProvider.degressivePurchaseRates.push({
        quantityMin: number + 0.01,
        quantityMax: null,
        price: 0,
        discount: 0,
        ecoContribution: 0,
        salePrice: 0,
      });
      this.key++;
    },
    changeQuantityMaxProvider(indexDegressive) {
      for (
        let i = indexDegressive + 1;
        i < this.productProvider.degressivePurchaseRates.length;
        i++
      ) {
        let element = this.productProvider.degressivePurchaseRates[i];
        if (i == this.productProvider.degressivePurchaseRates.length - 1) {
          element.quantityMin =
            Number(
              this.productProvider.degressivePurchaseRates[i - 1].quantityMax
            ) + 0.01;
          element.quantityMax = null;
        } else {
          let number = Number(
            this.productProvider.degressivePurchaseRates[i - 1].quantityMax
          );
          element.quantityMin = number + 0.01;
          element.quantityMax = number + 3;
        }
      }
      this.key++;
    },
    deleteDegressivePurchaseRateLocal(index, element) {
      if (index == 0) {
        this.productProvider.degressivePurchaseRates[index + 1].quantityMin = 0;
      } else if (
        index ==
        this.productProvider.degressivePurchaseRates.length - 1
      ) {
        this.productProvider.degressivePurchaseRates[index - 1].quantityMax =
          null;
      } else {
        this.productProvider.degressivePurchaseRates[index + 1].quantityMin =
          element.quantityMin;
      }
      if (element.id) {
        this.deleteDegressivePurchaseRate(element.id);
      }
      this.productProvider.degressivePurchaseRates.splice(index, 1);
      this.key++;
    },
    newProvider() {
      this.$router.push({ path: "/directory/new-company/fournisseurs/4" });
    },
  },
  components: {
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadioGroup,
    quillEditor,
    Treeselect,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BTable,
  },
};
</script>
<style scoped>
@media screen and (max-width: 750px) {
  .content-scrollable-sticky {
    height: calc(100% - 150px) !important;
  }
}

.radio-form-product.demo-inline-spacing > * {
  margin-top: 10px !important;
}

.floating-item-left {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.floating-item-right {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.floating-item-right-label {
  position: absolute;
  right: -20px;
  top: 50%;
}

.header-supplier-price {
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #ccc;
  margin-top: 10px !important;
  border-radius: 3px;
}

.form-control:disabled {
  background-color: #efefef !important;
  border: 1px solid #d8d6de !important;
}
</style>
